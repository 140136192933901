import { Button, createStyles,
        Grid, List, makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Content } from "../Content";
import { Nav } from "../Nav/Nav";
import { AddMemberModal } from "./AddMemberModal";
import { getAllTeamMembers } from "../../util/requests/smartCoach";
import MemberItem from "./MemberItem";
import ResponseFeedback from "../Feedback/ResponseFeedback";
import "./index.css";

const useStyles = makeStyles((theme) =>
  createStyles({
    gridItemRoot: {
      marginTop: 34,
      backgroundColor: theme.palette.background.paper,
      padding: "34px",
      borderRadius: "4px",
      overflow: "auto",
    },
  })
);

export const Admin = (props) => {
  const classes = useStyles();
  const [allMemberStats, setAllMemberStats] = useState({
    team: [],
    invited: [],
  });
  const [showInviteDialog, setShowInviteDialog] = useState(false);
  const [email, setEmail] = useState("");
  const [feedbackInfo, setFeedbackInfo] = useState({
    open: false,
    type: "",
    message: "",
  });

  useEffect(() => {
    const getData = async () => {
      try {
        const stats = await getAllTeamMembers();
        setAllMemberStats(stats);
      } catch (error) {}
    };
    getData();
  }, [showInviteDialog]);

  return (
    <>
      <ResponseFeedback
        feedbackInfo={feedbackInfo}
        setFeedbackInfo={setFeedbackInfo}
      />
      <AddMemberModal show={showInviteDialog} setShow={setShowInviteDialog} />
      <Content>
        <Grid container>
          <Grid item sm={12}>
            <Nav title="Admin" subTitle="" />
          </Grid>
          <Grid container classes={{ root: classes.gridItemRoot }}>
            <Grid item sm={6} style={{ marginBottom: 42 }}>
              <div className="teamProgressMemberFilterTitle">Team Members</div>
            </Grid>
            <Grid item sm={6} style={{ marginBottom: 42, textAlign: "end" }}>
              <Button style={{background: "var(--restoic-red)", color: "white" }}
                onClick={() => setShowInviteDialog(true)}>
                Invite
              </Button>
            </Grid>
            <Grid item sm={12}>
              <List>
                {allMemberStats.team.map((member) => (
                  <MemberItem
                    key={member.user.id}
                    member={member}
                    allMemberStats={allMemberStats}
                    setAllMemberStats={setAllMemberStats}
                    setFeedbackInfo={setFeedbackInfo}
                  />
                ))}
                {allMemberStats.invited.map((member) => (
                  <MemberItem
                    key={member.email}
                    member={member}
                    allMemberStats={allMemberStats}
                    setAllMemberStats={setAllMemberStats}
                    setFeedbackInfo={setFeedbackInfo}
                  />
                ))}
              </List>
            </Grid>
          </Grid>
        </Grid>
      </Content>
    </>
  );
}
