import React, { useState } from "react";
import { Dialog, DialogContent, Grid, TextField,
        Snackbar, IconButton, makeStyles, Theme,
        createStyles, MenuItem, Select } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import MuiAlert from '@material-ui/lab/Alert';
import { inviteTeamMember } from '../../util/requests/smartCoach';

const Alert = (props) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((Theme) =>
  createStyles({
    close: {
      padding: Theme.spacing(0.5),
    },
  }),
);

export const AddMemberModal = (props) => {
  const { show, setShow } = props;
  const classes = useStyles();
  const [emailValueError, setEmailValueError] = useState(false);
  const [inviteInProgress, setInviteInProgress] = useState(false);
  const [showEmailSuccess, setShowEmailSuccess] = useState(false);
  const [showEmailFailed, setShowEmailFailed] = useState(false);
  const [memberType, setMemberType] = useState("ATHLETE");
  const [email, setEmail] = useState("");
  const [isStaff, setIsStaff] = useState(false);
  const handleInvite = async () => {
    const emailVal = email.trim();
    if (emailVal === "") {
      setEmailValueError(true);
      return;
    }
    setInviteInProgress(true);
    if (inviteInProgress) return;
    try {
      await inviteTeamMember(emailVal, memberType);
      setShowEmailSuccess(true);
      setShow(false);
      setMemberType("ATHLETE");
      setEmail("");
    } catch (error) {
      console.log(error);
      setShowEmailFailed(true);
    }
    setInviteInProgress(false);
  }

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        open={showEmailSuccess}
        autoHideDuration={5000}
        onClose={() => { setShowEmailSuccess(false) }}
      >
        <Alert
          style={{ alignItems: "center" }}
          severity="success"
          action={
            <React.Fragment>
              <IconButton
                aria-label="close"
                color="inherit"
                className={classes.close}
                onClick={() => { setShowEmailSuccess(false) }}
              >
                <CloseIcon />
              </IconButton>
            </React.Fragment>
          }
        >
          Successfully sent an invite email!
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        open={showEmailFailed}
        autoHideDuration={5000}
        onClose={() => { setShowEmailFailed(false) }}
      >
        <Alert
          style={{ alignItems: "center" }}
          severity="error"
          action={
            <React.Fragment>
              <IconButton
                aria-label="close"
                color="inherit"
                className={classes.close}
                onClick={() => { setShowEmailFailed(false) }}
              >
                <CloseIcon />
              </IconButton>
            </React.Fragment>
          }
        >
          Failed to send the invite email!
        </Alert>
      </Snackbar>

      <Dialog onClose={() => { setShow(false) }} aria-labelledby="invite-dialog" open={show}>
        <DialogContent style={{ padding: 61, textAlign: "center" }}>
          <div className="nextWeekModalItemLogo"></div>
          <div className="nextWeekModalTitle">Invite a New Team Member</div>
          <Grid
            container
            justify="center"
            alignItems="center"
            spacing={4}
          >
            <Grid item sm={10}>
              <Select
                fullWidth
                labelId="Member Type"
                id="select"
                value={memberType}
                onChange={(e) => {
                  setMemberType(e.target.value);
                }}
              >
                <MenuItem value="ATHLETE">Athlete</MenuItem>
                <MenuItem value="STAFF">Staff</MenuItem>
              </Select>
            </Grid>
            <Grid item sm={10}>
              <TextField
                autoFocus
                margin="dense"
                id="email"
                label="Email Address"
                type="email"
                fullWidth
                error={emailValueError}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              />
            </Grid>
            <Grid item sm={6}>
              <div
                className="taskModalButton"
                style={{ background: "#e3e7eb", color: "black" }}
                onClick={() => setShow(false)}
              >
                CANCEL
              </div>
            </Grid>
            <Grid item sm={6}>
              <div 
                className={inviteInProgress ? "taskModalButton disabled" : "taskModalButton"}
                onClick={handleInvite}
              >
                INVITE
              </div>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
}